import { Tab } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import { gql, useMutation } from "@apollo/client";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [data1, setData1] = useState<any>({});
  const [data2, setData2] = useState<any>({});
  const [loadingBtn, setLoading] = useState(false);
  const navigate = useNavigate();
  var id=Math.random().toString().slice(2, 11)

  useEffect(() => {
    if (
      !localStorage.getItem("booking2Data") ||
      !localStorage.getItem("booking1Data")
    ) {
      navigate("/");
    } else {
      setData1(JSON.parse(localStorage.getItem("booking1Data")!));
      setData2(JSON.parse(localStorage.getItem("booking2Data")!));
    }
  }, []);
  function total(){
      if(data1.nights){
                  return data1?.experience?.cost * data1?.nights * data2?.people?.adults}
                  else{
                return data1?.experience?.cost * data2?.people?.adults
                  } 
          }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={data1?.experience?.image} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {data1?.experience?.city}, {data1?.experience?.country}
              </span>
              <span className="text-base font-medium mt-1 block">
                {data1?.experience?.title}
              </span>
            </div>

            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
           {data1.nights?
            <>
            <span>
              R{data1?.experience?.cost}x {data1.nights} days x{" "}
              {data2?.people?.adults} People
            </span> 
            <span>
              {total()}
            </span>
            </>:<>
            <span>
              R{data1?.experience?.cost}x{" "}
              {data2?.people?.adults} People
            </span>
            <span>
              {total()}
            </span>
            </>
              
            } 
            </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>R0</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              {total()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  // function CreateBooking() {

  const CREATE_BOOKING = gql`
    mutation createBooking(
      $experience: String!
      $name: String!
      $email: String!
      $phoneNumber: String!
      $surname: String!
      $paymentId: String!
      $status: Booking_status_MutationInput
      $adults: Float
      $children: Float
      $infants: Float
      $amount: Float
      $startDate: String!
      $endDate: String!
    ) {
      createBooking(
        data: {
          experience: $experience
          name: $name
          surname: $surname
          email: $email
          phoneNumber: $phoneNumber
          status: $status
          paymentId:$paymentId
          adults: $adults
          children: $children
          infants: $infants
          amount: $amount
          startDate: $startDate
          endDate: $endDate
        }
      ) {
        name
        id
      }
    }
  `;

  const [book, { data, loading, error }] = useMutation(CREATE_BOOKING);

  const customerInfo = () => {
    return (
      <div>
        <div className="space-y-1">
          <Label>Name </Label>
          <Input
            defaultValue={name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="space-y-1">
          <Label>Surname </Label>
          <Input
            defaultValue={surname}
            placeholder="Surname"
            onChange={(e) => setSurname(e.target.value)}
          />
        </div>
        <div className="space-y-1">
          <Label>Email</Label>
          <Input
            defaultValue={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="space-y-1">
          <Label>Phone Number</Label>
          <Input
            defaultValue={phone}
            placeholder="Phone"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>
    );
  };
  const renderMain = () => {
    function verifyform(type: string) {
      if (!name || !surname || !email || !phone) {
        setMessage("No Input should be empty");
      } else {
        setMessage("");
        setLoading(true);
        book({
          variables: {
            experience: data1?.experience.id,
            name: name,
            surname: surname,
            email: email,
            phoneNumber: phone,
            status: type === "now" ? "booked" : "prebooked",
            adults: data2?.people?.adults,
            children: data2?.people?.children,
            infants: data2?.people?.infants,
            amount:total(),
            paymentId:id+email,
            startDate: data1?.dates?.start,
            endDate: data1?.dates?.end,
          },
        });
        setTimeout(function () {
          setLoading(false);
          if (type === "later") {
            navigate("/pay-done");
          }
        }, 1000);
      }
    }
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* <div>
          <div>
            <h3 className="text-2xl font-semibold">Your trip</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Bookin details"
            />
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <ModalSelectDate
              defaultValue={rangeDates}
              onSelectDate={setRangeDates}
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 "
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString(rangeDates)}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

            <ModalSelectGuests
              defaultValue={guests}
              onChangeGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (guests.guestAdults || 0) +
                          (guests.guestChildren || 0)
                        } Guests, ${guests.guestInfants || 0} Infants`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />
          </div>
        </div> */}

        <div>
          <h3 className="text-2xl font-semibold">Payment method</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <img className="w-8" src={visaPng} alt="" />
            <img className="w-8" src={mastercardPng} alt="" />
            <Tab.Group>
              <Tab.List className="flex my-5">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      Pay Now
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      <span className="mr-2.5">Pay Later</span>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  {customerInfo()}
                  {/* <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div> */}
                  {/* <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}
                  <div className="pt-8">
                    <form
                      action={process.env.REACT_APP_URL ? process.env.REACT_APP_URL :"https://www.payfast.co.za/eng/process"}
                      method="post"
                      onSubmit={() => verifyform("now")}
                    >
                      <input
                        type="hidden"
                        name="merchant_id"
                        value={process.env.REACT_APP_ID ? process.env.REACT_APP_ID : "22988724"}
                      />
                      <input
                        type="hidden"
                        name="merchant_key"
                        value={process.env.REACT_APP_KEY ? process.env.REACT_APP_KEY : "vin0l9ywjbacf"}
                      />
                      <input
                        type="hidden"
                        name="cancel_url"
                        value={window.location.href}
                      />
                       <input
                        type="hidden"
                        name="return_url"
                        value={window.location.origin + "/pay-done"}
                      />
                       {/* <input
                        type="hidden"
                        name="notify_url"
                        value={window.location.origin + "/pay-done"}
                      /> */}
                      <input type="hidden" name="name_first" value={name} />
                      <input type="hidden" name="name_last" value={surname} />
                      <input type="hidden" name="email_address" value={email} />
                      <input type="hidden" name="cell_number" value={phone} />
                      <input
                        type="hidden"
                        name="m_payment_id"
                        value={id+email}
                      ></input>
                      <input
                        type="hidden"
                        name="amount"
                        value={total()}
                      />
                      <input
                        type="hidden"
                        name="item_name"
                        value={data1?.experience?.title}
                      />
                      {loadingBtn ? (
                        <div>...Loading</div>
                      ) : (
                        <ButtonPrimary type="submit">
                          Make Payment
                        </ButtonPrimary>
                      )}
                    </form>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  {customerInfo()}
                  <div className="pt-8">
                    {loadingBtn ? (
                      <div>...Loading</div>
                    ) : (
                      <ButtonPrimary onClick={() => verifyform("later")}>
                        Pre Book
                      </ButtonPrimary>
                    )}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="text-red-600">{message}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
