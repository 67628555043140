import React from "react";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  data?: any;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "Stays in Tokyo",
  subHeading,
  data,
}) => {
  const location = useLocation();
  const places = () => {
    if (location.pathname.includes("/city")) {
      return (
        <h2 className="text-4xl font-semibold">
          {data?.Experiences?.docs?.[0]?.city?.name
            ? "Experiences in" + " " + data.Experiences.docs[0].city.name
            : "No Places Found"}
        </h2>
      );
    }
    return <h2 className="text-4xl font-semibold">All Experiences</h2>;
  };
  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      {places()}
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          <span className="mx-2">·</span>
          {data.Experiences.totalDocs} stays
          <span className="mx-2">·</span>
        </span>
      )}
    </div>
  );
};

export default Heading2;
