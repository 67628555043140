import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";
import mpho from "images/3.png";
import iap from "images/1.png";
import abiri from "images/2.png";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
  link: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Mpho Mashego`,
    job: "Co-founder and Chief Executive",
    avatar: mpho,
    link: "https://business.bookinpal.com"
  },
  {
    id: "4",
    name: `IAMPresenting Pty Ltd`,
    job: "Partner",
    avatar: iap,
    link: "https://iampresenting.com/services"
  },
  {
    id: "2",
    name: `Abiri Innovations`,
    job: "Partner",
    avatar: abiri,
    link: "https://www.abiriinovations.com/"
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="IncHub Polokwane is a digital media cooperate offering solutions and services in digital
media marketing, software and electronic through in-house collaboration by member
startups and entrepreneurs."
      >
        ⛱ Team
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <a href={item.link}>
              <NcImage
                containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                className="absolute inset-0 object-cover"
                src={item.avatar}
              />
              <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
                {item.name}
              </h3>
              <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                {item.job}
              </span>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
