import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Bookin React Template</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="We look to personalise how users interact with software as well as the whole service offering with
everything in App tailored to the users preferences. Bookinpal is a solution geared at unlocking the
vast array of experiences Southern Africa has to offer. Experiences by the people with the people
is what we look to offer, a community of travel enthusiasts offering the best packages at affordable
and manageable rates for the ultimate hometown experience.
A software solution in essence, we guided by an afrocentric approach to product development and
economics, bookinpal is a Personalised subscription based travel & holiday consumer ERP
booking solution."
        />

        <SectionFounder />
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div> */}

        <SectionStatistic />

        {/* //zz <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
