import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Become a host 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Read and share new perspectives on just about any topic. Everyone’s
          welcome.
        </span>
        {/* <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more discount
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get premium magazines
            </span>
          </li>
        </ul> */}
        <iframe
          title="contact-target"
          name="dummyframe"
          id="dummyframe"
          style={{ display: "none" }}
        ></iframe>
        <form
          className="flex flex-col mt-10 max-w-sm"
          action="https://script.google.com/macros/s/AKfycbzLw6XnHyeIg0IfCOVDTMdHlUdR1ay8R_n3V4IC58KWnK6p9XIxtYCznJJ_hXZVROAfEw/exec"
          method="post"
          data-email="mpho@plkinchub.com"
          target="dummyframe"
        >
          <Input
            required
            className="mb-2"
            aria-required
            placeholder="First Name"
            type="text"
            rounded="rounded-full"
            name="name"
            id="name"
          />
          <Input
            required
            className="mb-2"
            aria-required
            placeholder="Last Name"
            type="text"
            rounded="rounded-full"
            name="surname"
            id="surname"
          />
          <Input
            required
            className="mb-2"
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
            name="email"
            id="email"
          />
          <ButtonCircle type="submit" className="">
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
