import React, { FC } from "react";
import StayCard from "components/StayCard/StayCard";
import Heading2 from "components/Heading/Heading2";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: any;
  loading?: any;
  error?: any;
}
const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  loading,
  error,
}) => {
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : Please refresh page</p>;
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 data={data} />

      <div className="mb-8 lg:mb-11">{/* //zz <TabFilters /> */}</div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.Experiences.docs.map((stay: any, index: number) => (
          <StayCard key={stay.id} data={stay} />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <Pagination /> */}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
