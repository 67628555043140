import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";

import { useQuery, gql } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const { cityId } = useParams();
  let GET_EXPERIENCES = null;

  if (cityId) {
    GET_EXPERIENCES = gql`
      query Experiences($equals: String!, $allowed: Boolean) {
        Experiences(
          where: { city: { equals: $equals }, verified: { equals: $allowed } }
        ) {
          totalDocs
          docs {
            id
            title
            price
            address
            singleDayExperience
            fixedDaysExperience
            city {
              id
              name
            }
            category {
              type
            }
            slider {
              image {
                url
                id
              }
            }
          }
        }
      }
    `;
  } else {
    GET_EXPERIENCES = gql`
      query Experiences($allowed: Boolean,$limit: Int) {
        Experiences(where: { verified: { equals: $allowed } },limit:$limit) {
          totalDocs
          limit
          docs {
            id
            title
            price
            address
            singleDayExperience
            fixedDaysExperience
            city {
              id
              name
            }
            category {
              type
            }
            slider {
              image {
                url
                id
              }
            }
          }
        }
      }
    `;
  }

  const { loading, error, data } = useQuery(GET_EXPERIENCES, {
    variables: {
      equals: cityId,
      allowed: true,
      limit:1000
    },
  });
  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>BookingPal</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Stays"
          currentTab="Stays"
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        />

        {/* SECTION */}
        <SectionGridFilterCard
          className="pb-24 lg:pb-28"
          loading={loading}
          data={data}
          error={error}
        />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of experiences"
            subHeading="Explore southern Africa with Southern Africans"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>

        {/* SECTION */}
        {/*//zz <SectionSubscribe2 className="py-24 lg:py-28" /> */}
        <SectionSubscribe2 className="py-24 lg:py-28" />

        {/* SECTION */}
        {/* //zz <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
    </div>
  );
};

export default ListingStayPage;
